.embla {
  --slide-size: 100%;
  --slide-size-sm: 50%;
  --slide-size-lg: calc(100% / 3);
}

.opportunities_carousel__slide {
  transform: translate3d(0, 0, 100px);
  flex: 0 0 var(--slide-size);
}

.opportunities_carousel__slide__number {
    transition: transform 0.1s ease-in-out, box-shadow 0.3s ease-out, z-index 0s;
}

.opportunities_carousel__slide {
    transition: z-index 0s;
}


@media (min-width: 768px) {
  .opportunities_carousel__slide {
    flex: 0 0 var(--slide-size-sm);
  }
}

@media (min-width: 1280px) {
  .opportunities_carousel__slide {
    flex: 0 0 var(--slide-size-lg);
  }

}

