.skeleton-loader:empty {
    width: 100%;
    min-height: 12px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.12);
    position: relative;
}

.skeleton-loader:empty:after {
    animation: skeletonLoading 1.5s infinite;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(-100%);
    z-index: 1;
    background: linear-gradient(90deg,
            hsla(0, 0%, 100%, 0),
            hsla(0, 0%, 100%, 0.3),
            hsla(0, 0%, 100%, 0));
}

@-webkit-keyframes skeletonLoading {
    to {
        transform: translateX(100%);
    }
}

@keyframes skeletonLoading {
    to {
        transform: translateX(100%);
    }
}

.blog-details h3:empty {
    width: 40%;
}

.blog-metadata .skeleton-loader:empty {
    width: 130px;
}

/* BLOG CONTENT WORDPRESS */
.entry-content h1 {
    @apply h2-regular my-4;
}

.entry-content h2 {
    @apply h3-regular my-4;
}

.entry-content h3 {
    @apply h4-regular leading-4 my-4;
}

.entry-content h4 {
    @apply h5-regular my-4;
}

.entry-content h5 {
    @apply h6-regular my-4;
}

.entry-content h6 {
    @apply h6-regular my-4;
}

.entry-content h1 strong,
.entry-content h2 strong,
.entry-content h3 strong,
.entry-content h4 strong,
.entry-content h5 strong,
.entry-content h6 strong{
    font-weight: 400!important;
}

.entry-content p strong,
.entry-content ul strong,
.entry-content ol strong,
.entry-content table strong{
    font-weight: 600!important;
}

.entry-content a {
    @apply text-primary-500 font-semibold underline;
}

.entry-content p {
    @apply p3-regular mb-3 leading-8;
}

.entry-content img{
    @apply my-2;
}

.entry-content hr {
    @apply my-12 mx-auto;
}

.entry-content table {
    @apply mb-6 mx-0 max-w-full w-full p4-regular overflow-hidden border border-collapse;
    border-spacing: 0;
    empty-cells: show;
}

.entry-content table+figcaption {
    @apply -mt-6 mb-12;
}

.entry-content .is-style-stripes table {
    border-collapse: inherit;
}

.entry-content .is-style-stripes tbody tr:nth-child(odd) {
    @apply bg-gray-100;
}

.entry-content thead {
    @apply align-middle whitespace-nowrap;
}

.entry-content th,
.entry-content td {
    @apply border p-2 leading-6 m-0 overflow-visible;
}

.entry-content .is-style-stripes th,
.entry-content .is-style-stripes td {
    border-color: transparent;
}

.entry-content caption {
    @apply bg-gray-100 text-center;
}

.entry-content blockquote {
    @apply border-l-2 pl-6 my-10;
}

.entry-content blockquote p {
    @apply p1-regular text-gray-600 mb-2;
}

.entry-content blockquote cite {
    @apply text-gray-400 font-medium;
}

.entry-content blockquote.is-style-large {
    @apply border-l-0 pl-0;
}

.entry-content blockquote.is-style-large p {
    @apply text-3xl text-gray-600;
}

.entry-content blockquote.is-style-large cite {
    @apply text-right;
}

.entry-content ul,
.entry-content ol {
    @apply my-2 ml-5 p3-regular list-outside;
}

.entry-content ul {
    @apply list-disc;
}

.entry-content ol {
    @apply list-decimal;
}

.entry-content pre {
    @apply whitespace-pre-wrap break-words p-6 border;
}

/* WORDPRESS PREDEFINED COLORS */
.entry-content .has-accent-color {
    color: theme('colors.pink[800]');
}

.entry-content .has-accent-background-color {
    background-color: theme('colors.pink[800]');
    color: white;
}

.entry-content .has-primary-color {
    color: black;
}

.entry-content .has-primary-background-color {
    background-color: black;
    color: theme('colors.secondary[50]');
}

.entry-content .has-secondary-color {
    color: theme('colors.gray[500]');
}

.entry-content .has-secondary-background-color {
    background-color: theme('colors.gray[500]');
    color: white;
}

.entry-content .has-subtle-background-color {
    color: theme('colors.secondary[900]/20%');
}

.entry-content .has-subtle-background-background-color {
    background-color: theme('colors.secondary[900]/20%');
    color: black;
}

.entry-content .has-background-color {
    color: theme('colors.secondary[50]');
}

.entry-content .has-background-background-color {
    background-color: theme('colors.secondary[50]');
    color: black;
}

.entry-content-mobile h1 {
    @apply h3-regular leading-7 my-3;
}

.entry-content-mobile h2 {
    @apply p1-semibold leading-6 my-3;
}

.entry-content-mobile h3 {
    @apply p2-semibold leading-6 my-3;
}

.entry-content-mobile h4 {
    @apply p3-semibold my-3;
}

.entry-content-mobile h5 {
    @apply p3-semibold my-3;
}

.entry-content-mobile h6 {
    @apply p3-semibold my-3;
}

.entry-content-mobile h1 strong{
    font-weight: 400!important;
}

.entry-content-mobile h2 strong,
.entry-content-mobile h3 strong,
.entry-content-mobile h4 strong,
.entry-content-mobile h5 strong,
.entry-content-mobile h6 strong{
    font-weight: 600!important;
}

.entry-content-mobile p strong,
.entry-content-mobile ul strong,
.entry-content-mobile ol strong,
.entry-content-mobile table strong{
    font-weight: 600!important;
}

.entry-content-mobile a {
    @apply text-primary-500 font-semibold underline;
}

.entry-content-mobile p {
    @apply mb-2 p4-regular leading-6;
}

.entry-content-mobile img{
    @apply my-2;
}

.entry-content-mobile hr {
    @apply my-12 mx-auto;
}

.entry-content-mobile table {
    @apply my-4 mx-0 max-w-full p5-regular w-full overflow-hidden border border-collapse;
    border-spacing: 0;
    empty-cells: show;
}

.entry-content-mobile table+figcaption {
    @apply -mt-6 mb-12;
}

.entry-content-mobile .is-style-stripes table {
    border-collapse: inherit;
}

.entry-content-mobile .is-style-stripes tbody tr:nth-child(odd) {
    @apply bg-gray-100;
}

.entry-content-mobile thead {
    @apply align-middle whitespace-nowrap;
}

.entry-content-mobile th,
.entry-content-mobile td {
    @apply border p-1 leading-5 m-0 overflow-visible;
}

.entry-content-mobile .is-style-stripes th,
.entry-content-mobile .is-style-stripes td {
    border-color: transparent;
}

.entry-content-mobile caption {
    @apply bg-gray-100 text-center;
}

.entry-content-mobile blockquote {
    @apply border-l-2 pl-6 my-8;
}

.entry-content-mobile blockquote p {
    @apply p2-regular text-gray-600 mb-2;
}

.entry-content-mobile blockquote cite {
    @apply text-gray-400 font-medium;
}

.entry-content-mobile blockquote.is-style-large {
    @apply border-l-0 pl-0;
}

.entry-content-mobile blockquote.is-style-large p {
    @apply text-3xl text-gray-600;
}

.entry-content-mobile blockquote.is-style-large cite {
    @apply text-right;
}

.entry-content-mobile ul,
.entry-content-mobile ol {
    @apply my-2 list-outside ml-6 p4-regular;
}

.entry-content-mobile ul {
    @apply list-disc;
}

.entry-content-mobile ol {
    @apply list-decimal;
}

.entry-content-mobile pre {
    @apply whitespace-pre-wrap break-words p-6 border;
}

/* WORDPRESS PREDEFINED COLORS */
.entry-content-mobile .has-accent-color {
    color: theme('colors.pink[800]');
}

.entry-content-mobile .has-accent-background-color {
    background-color: theme('colors.pink[800]');
    color: white;
}

.entry-content-mobile .has-primary-color {
    color: black;
}

.entry-content-mobile .has-primary-background-color {
    background-color: black;
    color: theme('colors.secondary[50]');
}

.entry-content-mobile .has-secondary-color {
    color: theme('colors.gray[500]');
}

.entry-content-mobile .has-secondary-background-color {
    background-color: theme('colors.gray[500]');
    color: white;
}

.entry-content-mobile .has-subtle-background-color {
    color: theme('colors.secondary[900]/20%');
}

.entry-content-mobile .has-subtle-background-background-color {
    background-color: theme('colors.secondary[900]/20%');
    color: black;
}

.entry-content-mobile .has-background-color {
    color: theme('colors.secondary[50]');
}

.entry-content-mobile .has-background-background-color {
    background-color: theme('colors.secondary[50]');
    color: black;
}

/* END: BLOG CONTENT */