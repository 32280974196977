.button-loader-white,
.button-loader-primary,
.button-loader-secondary {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    animation: rotate 1s linear infinite
}

.button-loader-white::before,
.button-loader-primary::before,
.button-loader-secondary::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    animation: prixClipFix 2s linear infinite;
}

.button-loader-white::before {
    border-color: white;
}

.button-loader-primary::before {
    border-color: theme('colors.primary[500]');
}

.button-loader-secondary::before {
    border-color: theme('colors.secondary[500]');
}

@keyframes rotate {
    100% {
        transform: rotate(360deg)
    }
}

@keyframes prixClipFix {
    0% {
        clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0)
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0)
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%)
    }

    75% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%)
    }

    100% {
        clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0)
    }
}