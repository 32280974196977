/* html formatter */
.jodit-formatter .jodit-source,
.jodit-formatter .jodit-source__mirror {
    background-color: theme('colors.gray.100') !important;
    color: theme('colors.basicBlack') !important;
}

.jodit-formatter .ace-idle-fingers {
    background-color: theme('colors.gray.100') !important;
    color: theme('colors.basicBlack') !important;
}

.jodit-formatter .ace-idle-fingers .ace_print-margin {
    background-color: theme('colors.gray.100') !important;
}

.jodit-formatter .ace-idle-fingers .ace_marker-layer .ace_active-line {
    background-color: theme('colors.gray.100') !important;
}

.jodit-formatter .ace-idle-fingers .ace_meta.ace_tag {
    color: theme('colors.gray.600') !important;
    font-weight: 600;
}

.jodit-formatter .ace-idle-fingers .ace_gutter,
.jodit-formatter .ace-idle-fingers .ace_gutter-active-line {
    background: theme('colors.gray.100') !important;
}

.jodit-formatter .ace-idle-fingers .ace_string {
    color: theme('colors.primary.500') !important;
}

.jodit-formatter .ace_gutter {
    display: none;
}

.jodit-formatter .ace_scroller {
    left: 0 !important;
}

.jodit-formatter .jodit-status-bar {
    display: none;
}

.jodit-formatter .ace-idle-fingers .ace_marker-layer .ace_selection{
    background-color: theme('colors.secondary.100')!important;
}

.jodit-formatter .jodit-workplace{
    flex-direction: row-reverse!important;
    gap: 0.5rem;
}

.jodit-formatter .jodit-container:not(.jodit_inline) .jodit-wysiwyg img{
    max-width: 600px;
}

.jodit-popup.jodit_theme_default.jodit-popup_strategy_leftbottom{
    display: none!important;
}

.jodit-formatter .jodit-toolbar__box{
    background-color: theme('colors.gray.100')!important;
}

strong{ font-weight: '600'!important; }