.asset-class-content-wrapper {
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.04);

}

.arrow-icon {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
    transition: 0.4s ease-in-out;
}

.asset-class-content-wrapper:hover .arrow-icon {
    transform: translate3d(0, 0, 0);
    opacity: 1;
}

.asset-class-content-wrapper:hover {
    box-shadow: 0px 1.85185px 3.14815px 0px rgba(0, 0, 0, 0.00),
        0px 8.14815px 6.51852px 0px rgba(0, 0, 0, 0.01),
        0px 20px 13px 0px rgba(0, 0, 0, 0.01),
        0px 38.51852px 25.48148px 0px rgba(0, 0, 0, 0.01),
        0px 64.81481px 46.85185px 0px rgba(0, 0, 0, 0.02),
        0px 100px 80px 0px rgba(0, 0, 0, 0.10);
}