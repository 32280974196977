.step-box {
    transition: 0.4s ease-in-out;
}

.step-box-0 {
    transform: translate3d(0, 0, 0);
}

.step-box-1 {
    transform: translate3d(0, 120%, 0);
}

.step-box-2 {
    transform: translate3d(0, 250%, 0);
}

.active-step {
    transition: background-color 0.4s step-end;
}

.step-text {
    transition: all 0.4s ease-in-out;
}

.step-details {
    transition: 0.4s ease-in-out;
}

.step-details-0 {
    transform: translate3d(0, 0, 0);
}

.step-details-1 {
    transform: translate3d(-100%, 0, 0);
}

.step-details-2 {
    transform: translate3d(-200%, 0, 0);
}

@media (max-width: 767px) {
    .step-box-1 {
        transform: translate3d(100%, 0, 0);
    }

    .step-box-2 {
        transform: translate3d(200%, 0, 0);
    }
}