@media only screen and (min-width: 768px) {
    #homepage-recent-deals .swiper-slide-active {
        z-index: 10;
        scale: 1.2;
    }

    #homepage-recent-deals .swiper-slide-active .opportunity-card {
        margin-top: 0px;
        margin-bottom: 0px;
        transform: translate3d(-60px, 0, 0);
    }

    #homepage-recent-deals :not(.swiper-slide-active) .opportunity-card {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 1024px) {
    #homepage-recent-deals .swiper-slide-active .opportunity-card {
        transform: translate3d(-20px, 0, 0);
    }
}